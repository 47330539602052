import styled from 'styled-components'

import { customMedia } from '../../utils/breakpoints'

export const AlertBannerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  background-color: #fee1c8;
  color: #000e40;
  font-size: 1.4rem;
  line-height: 170%;

  ${customMedia.lessThan('md')`
    justify-content: space-between;
  `}

  a {
    opacity: 0.8;
    color: #000e40;

    &:hover {
      opacity: 1;
    }
  }
`

export const AlertBannerClose = styled.button`
  background: none;
  border: 0;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 96px;
  padding: 8px;

  ${customMedia.lessThan('md')`
    margin-left: 24px;

    svg {
      width: 16px;
      height: 16px;
    }
  `}

  &:hover {
    opacity: 0.8;
  }
`
