import React, { useState } from 'react'
import * as S from './styled'

const AlertBanner = ({ text, link, linkText }) => {
  const [visible, setVisible] = useState(true)

  if (!visible || !text) return null

  const showLink = !!link && !!linkText

  const formatText = text => {
    return text
      .split(/\*\*\*(.*?)\*\*\*/g)
      .map((part, index) => (index % 2 === 1 ? <strong key={index}>{part}</strong> : part))
  }

  return (
    <S.AlertBannerContainer>
      <span>
        {formatText(text)} {showLink && <a href={link}>{linkText}</a>}
      </span>

      <S.AlertBannerClose onClick={() => setVisible(false)} aria-label="Fechar">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.99989 6.58565L1.63592 0.22168L0.221706 1.63589L6.58568 7.99986L0.22168 14.3639L1.63589 15.7781L7.99989 9.41408L14.3638 15.778L15.7781 14.3638L9.4141 7.99986L15.778 1.63594L14.3638 0.221724L7.99989 6.58565Z"
            fill="#161616"
            fill-opacity="0.88"
          />
        </svg>
      </S.AlertBannerClose>
    </S.AlertBannerContainer>
  )
}

export { AlertBanner }
